:root {
  --dark-blue-color: #1c365b;
  --blue-color: #3765b9;
  --green-color: #36b74f;
}

body {
  font-family: Titillium Web, sans-serif;
}

#logo {
  width: 34rem;
  margin: 2rem 0;
}

#title {
  width: 50rem;
  z-index: 1;
  background-image: url("title.0fa779d1.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto;
  padding: 3rem 0;
  position: relative;
}

#title .text {
  color: var(--dark-blue-color);
  font-size: 280%;
  font-weight: 600;
  line-height: 120%;
}

#title .text a {
  color: var(--dark-blue-color);
}

#message {
  color: #fff;
  width: 50rem;
  z-index: 0;
  background-image: url("rpo_part_blue.13083a8a.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto;
  padding-top: 9rem;
  padding-bottom: 5rem;
  font-size: 260%;
  line-height: 120%;
  position: relative;
  top: -10rem;
}

#message .text {
  padding: 0 6rem 0 11rem;
  line-height: 120%;
}

#message .last-phrase {
  margin-top: 2rem;
  font-weight: 600;
}

#message a {
  color: #fff;
  font-weight: bold;
}

.footer {
  background-color: var(--dark-blue-color);
}

.logo-mise {
  font-size: 180%;
  font-weight: 600;
  line-height: 120%;
}

/*# sourceMappingURL=index.5b1f6db5.css.map */
