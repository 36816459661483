:root {
  --dark-blue-color: #1c365b;
  --blue-color: #3765b9;
  --green-color: #36b74f;
}

body {
  font-family: "Titillium Web", sans-serif;
}

#logo {
    margin: 2rem 0;
    width: 34rem;
}

#title {
    width: 50rem;
    /* max-width: 920px; */
    background-image: url(img/title.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
    padding: 3rem 0rem;
    position: relative;
    z-index: 1;
}

#title .text {
    color: var(--dark-blue-color);
    font-size: 280%;
    line-height: 120%;
    font-weight: 600;
}

#title .text a {
    color: var(--dark-blue-color);
}

#message {
    background-image: url(img/rpo_part_blue.svg);
    color: white;
    width: 50rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
    position: relative;
    top: -10rem;
    z-index: 0;
    padding-top: 9rem;
    padding-bottom: 5rem;
    font-size: 260%;
    line-height: 120%;
}

#message .text {
    line-height: 120%;
    padding: 0rem 6rem 0rem 11rem;
}

#message .last-phrase {
    margin-top: 2rem;
    font-weight: 600;
}

#message a {
    color: white;
    font-weight: bold;
}

.footer {
    background-color: var(--dark-blue-color);
}

.logo-mise {
    font-size: 180%;
    font-weight: 600;
    line-height: 120%;
}